import { codingbarApi } from 'codingbar-api';
import { checkIsShowTranslateRoute } from 'shared/api/Translation';
import { fetchStaffPermission } from 'shared/api/StaffPermissions';
import { INTERNAL_MAIL_RECEIVER } from 'shared/api/collections';
import { multiExerciseTypeOptionMap } from 'redux/constants/excercise';
import isEmpty from 'lodash/isEmpty';

export function isBrandUI() {
	const brand = localStorage.getItem("brand");

	if (brand === 'benq') {
		return true;
	}
	return false;
}
export function isCSFC() {
	//	TODO: Jessie - 只是暫時的route, 之後入口domain會改成 https://exam.csfcasia.org 要記得改掉這裡 #1141
	const { pathname, host, search } = window.location;
	if (host === 'exam.csfcasia.org') return true;
	if (pathname === '/csfcasia' || pathname === '/csfcasia/' || pathname.includes('/csfcasia/') || pathname.includes('/csfcasia?')) return true;	
	if (search.includes('isCSFCTest')) return true;	
	return false;
};

export function isAiMate(type) {
	if (type === 'route') {
		const { pathname } = window.location;
		return pathname.includes('AImate') || pathname.toLowerCase().includes('aimate');
	}
	const user = codingbarApi.getAuthService().getUser();
	if (type === 'workspace') {
		return user && user.workspaces && user.workspaces.includes('aimate');
	}

	if (type === 'aiMateOnly') {
		return user && user.workspaces && user.workspaces.length === 1 && user.workspaces.includes('aimate');
	}
	return false;
}

export function isZoomAllowed() {
	const user = codingbarApi.getAuthService().getUser();
	// console.log("isZoomAllowed", user);
	const whiteList = ["app.codingbar.ai", "localhost:8080"];

	if (window.location && !whiteList.find(host => host === window.location.host)) {
		return false;
	}

	if (isCSFC()){
		return false;
	}

	if (!user) {
		return false;
	}

	if (isAiMate('route') && isAiMate('workspace')) return false;

	if (user.unit === "airabbi" || (user.units && user.units.find(u => u === "airabbi"))) {
		return true;
	}

	return true;
}

export function isSmartAgentAllowed() {
	const user = codingbarApi.getAuthService().getUser();
	// console.log("isSmartAgentAllowed", user);

	if (!user) {
		return false;
	}

	if (user.unit === "airabbi" || (user.units && user.units.find(u => u === "airabbi"))) {
		return true;
	}

	return true;
}

export function CanPackageCourseViewed() {
	const user = codingbarApi.getAuthService().getUser();
	// console.log("isPackageCourseViewed", user);

	if (!user) {
		return false;
	}

	if (user.unit === "airabbi" || (user.units && user.units.find(u => u === "airabbi"))) {
		return false;
	}

	return true;
}

export function isAskQuestionAllowed() {
	const user = codingbarApi.getAuthService().getUser();
	// console.log("isAskQuestionAllowed", user);

	if (!user) {
		return false;
	}

	if (user.unit === "airabbi" || (user.units && user.units.find(u => u === "airabbi"))) {
		return true;
	}

	return true;
}

export function isMultipleExerciseTypeAllowed() {
	const user = codingbarApi.getAuthService().getUser();
	// console.log("isMultipleExerciseTypeAllowed", user);
	const hostname = window.location.hostname;
	
	if (!user) {
		return false;
	}

	if (hostname.includes("school")) {
		return false;
	}

	return true;
}

export function isCRMAllowed() {

	const user = codingbarApi.getAuthService().getUser();
	
	if (user.unit !== "airabbi") {
		return false;
	}

	if (!user) {
		return false;
	}

	return fetchStaffPermission(user.uid).then((res) => {
		const userIsAllowedCRM = res && res.permissions.isCRMAllowed

		return userIsAllowedCRM ? userIsAllowedCRM : false;
	})

	// const crmUsers = [
	// 	"jerry@airabbi.com",
	// 	"bonnie@airabbi.com",
	// 	"april@airabbi.com",
	// 	"huangtingjie@hotmail.com.tw",
	// 	"dev.tengi@gmail.com",
	// 	"kopulin@airabbi.com", //
	// 	"houy062924@gmail.com",
	// 	"a800907@gmail.com",
	// 	"solchi.workmail@gmail.com", //
	// 	"slimecrm@airabbi.com",
	// 	"amanda.tang@airabbi.com",

	// ]
	// return crmUsers.find(email => email === user.email) !== undefined;

}

export function isStaffAsDevAllowed() {
	const user = codingbarApi.getAuthService().getUser();
	
	if (!user) {
		return false;
	}

	return fetchStaffPermission(user.uid).then((res) => {
		const userIsAllowedStaffAsDev = res && res.permissions.isStaffAsDevAllowed

		return userIsAllowedStaffAsDev ? userIsAllowedStaffAsDev : false;
	})
}

export function isViewPackageCourseAirabbi() {
	const user = codingbarApi.getAuthService().getUser();
	
	if (!user) return false;

	// if (user.unit === "airabbi" || (user.units && user.units.includes("airabbi"))) {
	if (user.unit === "airabbi") {
		return true;
	}

	return false;
}

export function isPackageCourseManageAllowed() {
	const user = codingbarApi.getAuthService().getUser();

	if (!user) return false;

	const allowedUsers = [
		"developer-airabbi-com",
		"getsulin-gmail-com",
		"huangtingjie-hotmail-com-tw",
		"tryit163281-gmail-com",
		"yu-chang0130-gmail-com",
		"chris-lee-airabbi-com",
		"houy062924-gmail-com",
		"a800907-gmail-com",
		"tengjie-huang-airabbi-com",
		"solchi-workmail-gmail-com",

	]

	return allowedUsers.includes(user.uid);
}

export function isViewStudentInfoAllowed() {
	const user = codingbarApi.getAuthService().getUser();

	if (!user) return false;

	const allowedUsers = [
		"getsulin-gmail-com",
		"huangtingjie-hotmail-com-tw",
		"tryit163281-gmail-com",
		"yu-chang0130-gmail-com",
		"chris-lee-airabbi-com",
		"houy062924-gmail-com",
		"a800907-gmail-com",
		"tengjie-huang-airabbi-com",
		"solchi-workmail-gmail-com",
	]

	return allowedUsers.includes(user.uid);
}

export function fetchGlobalTestEmailInternalReceivers() {
	return codingbarApi.getCoreService().queryOne(INTERNAL_MAIL_RECEIVER, {key: 'apcsReport'}, {})
		.then(res => {
			return (res.status === 'success') ? res.result.receivers : [];
	})
	.catch(err => console.error(err))

	// return [
	// 	'april@airabbi.com',
	// 	'jerry@airabbi.com',
	// 	'kopulin@airabbi.com',
	// 	'yafan.ku@airabbi.com',
	// 	'jessie.hu@airabbi.com',
	// 	'tengjie.huang@airabbi.com',
	// 	'charlene.lin@airabbi.com',
	// 	'joe@airabbi.com',
	// ]
}

export function fetchCSFCTestEmailInternalReceivers() {
	return codingbarApi.getCoreService().queryOne(INTERNAL_MAIL_RECEIVER, { key: 'csfcReport' }, {})
		.then((res) => {
			return (res.status === 'success') ? res.result.receivers : [];
		}).catch((err) => console.error(err));
}

export function fetchSchoolTrialInternalReceivers() {
	return codingbarApi.getCoreService().queryOne(INTERNAL_MAIL_RECEIVER, {key: 'schoolTrialSentInfo'}, {})
	.then(res => {
		return (res.status === 'success') ? res.result.receivers : [];
	})
	.catch(err => console.error(err))
}

export function fetchFreeTrialInternalReceivers() {
	return codingbarApi.getCoreService().queryOne(INTERNAL_MAIL_RECEIVER, {key: 'freeTrialSentInfo'}, {})
	.then(res => {
		return (res.status === 'success') ? res.result.receivers : [];
	})
	.catch(err => console.error(err))
}

export function fetchCourseValidityPeriodReceivers() {
	return codingbarApi.getCoreService().queryOne(INTERNAL_MAIL_RECEIVER, {key: 'courseValidityPeriod'}, {})
		.then((res) => {
			return (res.status === 'success') ? res.result.receivers : []
		});
}

export function fetchAskQuestionInternalReceivers() {
	return codingbarApi.getCoreService().queryOne(INTERNAL_MAIL_RECEIVER, {key: 'askQuestion'}, {})
		.then(res => {
		return (res.status === 'success') ? res.result.receivers : [];
	})
	.catch(err => console.error(err))
}

export function fetchFeedbackInternalReceivers() {
	return codingbarApi.getCoreService().queryOne(INTERNAL_MAIL_RECEIVER, {key: 'feedback'}, {})
	.then(res => {
		return (res.status === 'success') ? res.result.receivers : [];
	})
	.catch(err => console.error(err))
}

export function getInternalReceiversByKey(key) {
	return codingbarApi.getCoreService().queryOne(INTERNAL_MAIL_RECEIVER, { key }, { receivers: 1 })
		.then((res) => (res.status === 'success' ? res.result.receivers : []))
		.catch((err) => console.error(err));
}

export function isExportExerciseAllowed() {
	const user = codingbarApi.getAuthService().getUser();

	if (!user) return false;

	const allowedUsers = [
		"getsulin-gmail-com",
		"huangtingjie-hotmail-com-tw",
		"tryit163281-gmail-com",
		"yu-chang0130-gmail-com",
		"chris-lee-airabbi-com",
		"houy062924-gmail-com",
		"a800907-gmail-com",
		"tengjie-huang-airabbi-com",
		"solchi-workmail-gmail-com",
		"jessi_kuo-gotop-com-tw", // 碁峰資訊
		"sa-ttsh-tp-edu-tw", // 臺北市立大同高級中學
	]

	return allowedUsers.includes(user.uid);
}

export function isQATestAllowed() {
    const user = codingbarApi.getAuthService().getUser();

	if (!user) return false;

	const allowedUsers = [
		"slime-airabbi-com",
		"qatest-student-com",
		"qatestother-student-com",
		"qatestschool-student-com",
		"qatestunits-student-com",
	]

	return allowedUsers.includes(user.uid);
}

export function isCloneCourseAllowed() {
	const user = codingbarApi.getAuthService().getUser();
	
	const allowedUsers = [
		'getsulin-gmail-com',
		"huangtingjie-hotmail-com-tw",
		"tryit163281-gmail-com",
		"yu-chang0130-gmail-com",
		"chris-lee-airabbi-com",
		"solchi-workmail-gmail-com",
		"operation_staff-airabbi-com",
	];
	
	return (allowedUsers.includes(user.uid) || user.unit.toLowerCase() !== 'airabbi');
}

export function isAssignTranslateCourseAllowed() {
	const user = codingbarApi.getAuthService().getUser();
	
	const allowedUsers = [
		'getsulin-gmail-com',
		"huangtingjie-hotmail-com-tw",
		"tryit163281-gmail-com",
		"yu-chang0130-gmail-com",
		"chris-lee-airabbi-com",
		"solchi-workmail-gmail-com",
	];
	
	return allowedUsers.includes(user.uid);
}

export async function isShowTranslateRoute() {
	const user = codingbarApi.getAuthService().getUser();
	
	const hasTranslationCourse = await checkIsShowTranslateRoute(user.uid);
	
	return hasTranslationCourse;
}

export function internalTeacherShareResourceOptions() {

	const specTeacher = [
		{ label: "Slime", value: "getsulin-gmail-com" },
		{ label: "Joe", value: "tryit163281-gmail-com" },
		{ label: "Tillie", value: "huangtingjie-hotmail-com-tw" },
		{ label: "Taco", value: "yu-chang0130-gmail-com" },
		{ label: "Chris", value: "chris-lee-airabbi-com" },
	]

	return specTeacher;
}

export function isInternalTeacher() {
	const user = codingbarApi.getAuthService().getUser();

	const specTeacher = internalTeacherShareResourceOptions().reduce((arr, el) => {
		return [...arr, el.value]
	}, [])

	return specTeacher.includes(user.uid);
}

// 功能試用, 移除 beta 的過渡期
export function checkIsFeatureTrialUnit(unit) {
	const user = codingbarApi.getAuthService().getUser();
	const curUnit = unit || user.unit;
	if (!curUnit) return false;
	const allowedUnits = ['airabbi', 'Beta', 'BetaSchool', 'BetaVersion'];

	return !isEmpty(allowedUnits.filter((u) => u.toLowerCase() === curUnit.toLowerCase()));
}

export function getIsChatGPTAvailable(unitPermissions, courseId) {
	if (!unitPermissions) return false; // 沒有 data 則未開放
	if (!unitPermissions.chatGPT) return false;
	switch (unitPermissions.chatGPT.type) {
		case 'unit': // 根據單位開放
			return true;
		case 'course': { // 根據課程開放
			return unitPermissions.chatGPT.courseIds.includes(courseId);
		}
		case 'none': // 未開放
			return false;
		default:
			return false;
	}
}

export function getIsChatGPTNoPromptLimit(unitPermissions, courseId) {
	if (!unitPermissions) return false;
	if (!unitPermissions.chatGPT) return false;
	if (unitPermissions.chatGPT.type !== 'course') return false;
	return unitPermissions.chatGPT.courseIds.includes(courseId) && unitPermissions.chatGPT.noPromptLimitCourseIds.includes(courseId);
}

export function getAllowedMultiExerciseType(unitPermissions, isSchoolDomainUser) {
	if (unitPermissions && 'multiExerciseType' in unitPermissions && unitPermissions.multiExerciseType.length > 0) {
		return multiExerciseTypeOptionMap.all.filter((o) => unitPermissions.multiExerciseType.includes(o));
	} else if (!isSchoolDomainUser) {
		return multiExerciseTypeOptionMap.all;
	}
	return multiExerciseTypeOptionMap.basic;
}
