
import React from 'react';

import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom';

import RoleChecker from './checkers/RoleChecker';

import App from './containers/App/App';

// getComponent is a function that returns a promise for a component
// It will not be called until the first mount
function asyncComponent(getComponent) {
	return class AsyncComponent extends React.Component {
		static Component = null;
		state = { Component: AsyncComponent.Component };

		constructor() {
			super();

			getComponent().then((Component) => {
				AsyncComponent.Component = Component;
				this.setState({ Component });
			});
		}

		render() {
			const { Component } = this.state;
			if (Component) {
				return <Component {...this.props} />;
			}
			return null;
		}
	};
}

const DemoPage = asyncComponent(() =>
	import('./containers/Login/DemoPage').then((module) => module.default));

const Login = asyncComponent(() =>
	import('./containers/Login/Login').then((module) => module.default));

const LoginExam = asyncComponent(() =>
	import('./containers/Login/Login').then((module) => module.default));

const LoginTrial = asyncComponent(() =>
	import('./containers/Login/Login').then((module) => module.default));

const LoginCSFCPage = asyncComponent(() =>
	import('./containers/Login/Login').then(module => module.default));

const LoginRedirectPage = asyncComponent(() =>
	import('./containers/Login/SignUpEvent/LoginRedirectPage/LoginRedirectPage').then((module) => module.default));

const freeTrialNPBN = asyncComponent(() =>
	import('./containers/autoFreeTrial/freeTrialNPBN/freeTrialNPBN').then((module) => module.default));

const SchoolTrailVerifyPage = asyncComponent(() =>
	import('./containers/SchoolTrail/SchoolTrailVerifyPage').then((module) => module.default));

const SignUpPage = asyncComponent(() =>
	import('./containers/SignUp/SignUpPage').then((module) => module.default));

const EmailSentPage = asyncComponent(() =>
	import('./containers/SignUp/EmailSentPage').then((module) => module.default));

const EmailUnverifiedPage = asyncComponent(() =>
	import('./containers/SignUp/EmailUnverifiedPage').then((module) => module.default));

const FillInfoPage = asyncComponent(() =>
	import('./containers/SignUp/FillInfoPage').then((module) => module.default));

const PolicyPage = asyncComponent(() =>
	import('./containers/Login/Policy').then((module) => module.default));

const ResetPasswordPage = asyncComponent(() =>
	import('./containers/Login/ResetPasswordPage').then((module) => module.default));

const RequestResetPasswordPage = asyncComponent(() =>
	import('./containers/Login/RequestResetPasswordPage').then((module) => module.default));

const StudentExercise = asyncComponent(() =>
	import('./containers/StudentExercise/StudentExercise').then((module) => module.default));

// 3.0
const ExerciseEditorPage = asyncComponent(() =>
	import('./containers/ExerciseEditorPage/ExerciseEditorPage').then((module) => module.default));

const TeacherExercise = asyncComponent(() =>
	import('./containers/TeacherExercise/TeacherExercise').then((module) => module.default));

const CourseManage = asyncComponent(() =>
	import('./containers/CourseManage/CourseManage').then((module) => module.default));

const newStaffDashboardPage = asyncComponent(() =>
	import('./containers/NewStaffPage/DashboardPage/DashboardPage').then((module) => module.default));

const newStaffStudentPage = asyncComponent(() =>
	import('./containers/NewStaffPage/StaffStudentPage').then((module) => module.default));

const StudentCRM = asyncComponent(() =>
	import('./containers/CRM/Student/index').then((module) => module.default));

const CRMPage = asyncComponent(() =>
	import('./containers/CRM/CRM').then((module) => module.default));

const newStaffTeacherPage = asyncComponent(() =>
	import('./containers/NewStaffPage/StaffTeacherPage').then((module) => module.default));

const newStaffCoursePage = asyncComponent(() =>
	import('./containers/NewStaffPage/DashboardCourseSchedulePage').then((module) => module.default));

const newStaffPage = asyncComponent(() =>
	import('./containers/NewStaffPage/StaffPage').then((module) => module.default));

// const newStaffNotificationPage = asyncComponent(() =>
// 	import('./containers/NewStaffPage/StaffNotificationPage').then(module => module.default));

const newStaffInformationPage = asyncComponent(() =>
	import('./containers/NewStaffPage/StaffInformationPage').then((module) => module.default));

const AnnouncementManagePage = asyncComponent(() =>
	import('./containers/NewStaffPage/AnnouncementManagePage/AnnouncementManagePage').then((module) => module.default));

const UnitDomainManagePage = asyncComponent(() =>
	import('./containers/NewStaffPage/UnitDomainManagePage/UnitDomainManagePage').then((module) => module.default));

const BetaUnitManagePage = asyncComponent(() =>
	import('./containers/NewStaffPage/BetaUnitManagePage/BetaUnitManagePage').then((module) => module.default));

const UnitPermissionsManagePage = asyncComponent(() =>
	import('./containers/NewStaffPage/UnitPermissionsManagePage/UnitPermissionsManagePage').then((module) => module.default));

const CoursePackageManagePage = asyncComponent(() =>
	import('./containers/NewStaffPage/CoursePackageManagePage/CoursePackageManagePage').then((module) => module.default));

const TranslationManagePage = asyncComponent(() =>
	import('./containers/NewStaffPage/TranslationManagePage/TranslationManagePage').then((module) => module.default));

const CrmAccountManage = asyncComponent(() =>
	import('./containers/NewStaffPage/CrmAccountManage').then((module) => module.default));

const DataSyncPage = asyncComponent(() =>
	import('./containers/NewStaffPage/DataSyncPage/DataSyncPage').then((module) => module.default));

const InternalMailManage = asyncComponent(() =>
	import('./containers/NewStaffPage/InternalMailManage/InternalMailManage').then((module) => module.default));

const ToolsPage = asyncComponent(() =>
	import('./containers/NewStaffPage/ToolsPage/ToolsPage').then((module) => module.default));

const TeacherSchedulePage = asyncComponent(() =>
	import('./containers/NewStaffPage/TeacherSchedulePage/TeacherSchedulePage').then((module) => module.default));


// teacher
const TeacherCoursePage = asyncComponent(() =>
	import('./containers/NewTeacherPage/TeacherCoursePage').then((module) => module.default));

const TeacherCourseLessonDetailsPage = asyncComponent(() =>
	import('./containers/NewTeacherCoursePage/TeacherCourseLessonDetailsPage').then((module) => module.default));

const TeacherCourseStudentPage = asyncComponent(() =>
	import('./containers/NewTeacherCoursePage/TeacherCourseStudentPage').then((module) => module.default));
// codingbarV1
const TeacherCourseLessonPageOld = asyncComponent(() =>
	import('./containers/NewTeacherCoursePage/TeacherCourseLessonPage').then((module) => module.default));
// codingbarV2
const TeacherCourseLessonPageV2 = asyncComponent(() =>
	import('./containers/TeacherCourseLessonPage/CourseLessonPageOld/CourseLessonPage').then((module) => module.default));
// codingbarV3 (newest)
const TeacherCourseLessonPageV3 = asyncComponent(() =>
	import('./containers/TeacherCourseLessonPage/CourseLessonPage/CourseLessonPage').then((module) => module.default));

const TeacherCourseLivePage = asyncComponent(() =>
	import('./containers/NewTeacherCoursePage/TeacherCourseLivePage').then((module) => module.default));

const TeacherCourseTestPage = asyncComponent(() =>
	import('./containers/TeacherCourseTestPage/TeacherCourseTestPage').then((module) => module.default));
// codingbarV2
const TeacherCourseTestPageOld = asyncComponent(() =>
	import('./containers/NewTeacherCoursePage/TeacherCourseTestPage').then((module) => module.default));

const TeacherCourseScorePage = asyncComponent(() =>
	import('./containers/NewTeacherCoursePage/TeacherCourseScorePage').then((module) => module.default));

const TeacherCourseTestDetailsPage = asyncComponent(() =>
	import('./containers/NewTeacherCoursePage/TeacherCourseTestDetailsPage').then((module) => module.default));

const TeacherCourseStudentDetailPageOld = asyncComponent(() =>
	import('./containers/NewTeacherCoursePage/TeacherCourseStudentDetailPage').then((module) => module.default));

const TeacherCourseStudentDetailPage = asyncComponent(() =>
	import('./containers/TeacherCourseStudentPage/subpages/StudentDetailPage').then((module) => module.default));
// codingbarV1
// const TeacherResourcePageOld = asyncComponent(() =>
// 	import('./containers/NewTeacherPage/TeacherResourcePage').then(module => module.default));
// codingbarV2
const TeacherResourcePageOld = asyncComponent(() =>
	import('./containers/TeacherResourcePage/TeacherResourcePageOld').then((module) => module.default));

const TeacherResourcePage = asyncComponent(() =>
	import('./containers/TeacherResourcePage/TeacherResourcePage').then((module) => module.default));

const TeacherTutorialPage = asyncComponent(() =>
	import('./containers/NewTeacherPage/TeacherTutorialPage').then((module) => module.default));

const TeacherTutorialPageOld = asyncComponent(() =>
	import('./containers/NewTeacherPage/TeacherTutorialPageOld').then((module) => module.default));

const TeacherTestDetailsPage = asyncComponent(() =>
	import('./containers/NewTeacherTestPage/TeacherTestDetailsPage').then((module) => module.default));

const TeacherTestMainPage = asyncComponent(() =>
	import('./containers/TeacherTestPage/TeacherTestMainPage').then((module) => module.default));

const TeacherNotificationPage = asyncComponent(() =>
	import('./containers/NewTeacherPage/TeacherNotificationPage').then((module) => module.default));

const TeacherInformationPage = asyncComponent(() =>
	import('./containers/NewTeacherPage/TeacherInformationPage').then((module) => module.default));

// student
const StudentCoursePage = asyncComponent(() =>
	import('./containers/NewStudentPage/StudentCoursePage').then((module) => module.default));

const StudentLivePage = asyncComponent(() =>
	import('./containers/NewStudentPage/StudentLivePage').then((module) => module.default));

const StudentStudyResultPage = asyncComponent(() =>
	import('./containers/NewStudentPage/StudyResult/StudyResultPage').then((module) => module.default));

const StudentStudyResultCoursePage = asyncComponent(() =>
	import('./containers/NewStudentPage/StudyResultCourse/StudentStudyResultCoursePage').then((module) => module.default));

const StudentCourseLessonPage = asyncComponent(() =>
	import('./containers/NewStudentCoursePage/StudentCourseLessonPage').then((module) => module.default));

const StudentCourseLessonDetailsPage = asyncComponent(() =>
	import('./containers/NewStudentCoursePage/StudentCourseLessonDetailsPage').then((module) => module.default));

const StudentCourseExercisePage = asyncComponent(() =>
	import('./containers/NewStudentCoursePage/StudentCourseExercisePage').then((module) => module.default));

const StudentCourseLivePage = asyncComponent(() =>
	import('./containers/NewStudentCoursePage/StudentCourseLivePage').then((module) => module.default));

const StudentCourseTestPageOld = asyncComponent(() =>
	import('./containers/NewStudentCoursePage/StudentCourseTestPageOld').then((module) => module.default));

const StudentCourseTestPage = asyncComponent(() =>
	import('./containers/NewStudentCoursePage/StudentCourseTestPage').then((module) => module.default));
// codingbarV2
const StudentCourseTestDetailsPageOld = asyncComponent(() =>
	import('./containers/NewStudentCoursePage/StudentCourseTestDetailsPageOld').then((module) => module.default));

const StudentCourseTestDetailsPage = asyncComponent(() =>
	import('./containers/NewStudentCoursePage/StudentCourseTestDetailsPage').then((module) => module.default));

const StudentNotePage = asyncComponent(() =>
	import('./containers/NewStudentPage/StudentNotePage').then((module) => module.default));

const StudentChallengePage = asyncComponent(() =>
	import('./containers/NewStudentPage/StudentChallengePage').then((module) => module.default));

const StudentEditorPage = asyncComponent(() =>
	import('./containers/NewStudentPage/StudentEditorPage').then((module) => module.default));

const StudentNotificationPage = asyncComponent(() =>
	import('./containers/NewStudentPage/StudentNotificationPage').then((module) => module.default));

const StudentInformationPage = asyncComponent(() =>
	import('./containers/NewStudentPage/StudentInformationPage').then((module) => module.default));

const MaterialManagePage = asyncComponent(() =>
	import('./containers/MaterialManage/MaterialUploadFile').then((module) => module.default));

const StudentTestMainPageOld = asyncComponent(() =>
	import('./containers/StudentTest/StudentTestMainPageOld').then((module) => module.default));
// 公開模擬考與模擬練習合併, components 廢棄
// const StudentGlobalTest = asyncComponent(() =>
// 	import('./containers/StudentGlobalTest/StudentGlobalTest').then(module => module.default));
// const StudentGlobalTestOld = asyncComponent(() =>
// 	import('./containers/StudentGlobalTest/StudentGlobalTestOld').then(module => module.default));

const TeacherQuestionPage = asyncComponent(() =>
	import('./containers/TeacherQuestionPage/TeacherQuestionPage').then((module) => module.default));

const TeacherQuestionsLabel = asyncComponent(() =>
	import('./containers/TeacherQuestionsLabel/TeacherQuestionsLabel').then((module) => module.default));

const ExerciseErrorLabel = asyncComponent(() =>
	import('./containers/ExerciseErrorLabel/index').then((module) => module.default));

const StudentQuestionsPage = asyncComponent(() =>
	import('./containers/NewStudentPage/StudentQuestionsPage').then((module) => module.default));

const HtmlEditor = asyncComponent(() =>
	import('./components/RealHtmlEditor/RealHtmlEditor').then((module) => module.default));

const StaffDocumentsPage = asyncComponent(() =>
	import('./containers/NewStaffPage/DocumentsPage').then((module) => module.default));

// const TeacherRecruitForm = asyncComponent(() =>
//   import('./components/PlentyDifferentForms/teacherRecruitForm').then(module => module.default));

const FormForWorkShopAndCamp = asyncComponent(() =>
	import('./components/PlentyDifferentForms/FormForWorkShopAndCamp').then((module) => module.default));

const FormManage = asyncComponent(() =>
	import('./containers/FormManage/FormManage').then((module) => module.default));

const RegistrationManageOld = asyncComponent(() =>
	import('./containers/StaffPageOld/RegistrationManageOld').then((module) => module.default));

const RegistrationManage = asyncComponent(() =>
	import('./containers/RegistrationManage/RegistrationManage').then((module) => module.default));

const RegistrationManageListPageV1 = asyncComponent(() =>
	import('./containers/StaffPageOld/RegistrationManageListPageV1').then((module) => module.default));

const RegistrationManageListPageOld = asyncComponent(() =>
	import('./containers/StaffPageOld/RegistrationListPageOld').then((module) => module.default));

const RegistrationDownloadPage = asyncComponent(() =>
	import('./containers/NewStaffPage/RegistrationDownloadPage/RegistrationDownloadPage').then((module) => module.default));

const CouponManage = asyncComponent(() =>
	import('./containers/CouponManager/CouponManager').then((module) => module.default));

const ResultPage = asyncComponent(() =>
	import('./components/ResultPage/ResultPage').then((module) => module.default));

const TeacherRecruitFormConfirm = asyncComponent(() =>
	import('./components/PlentyDifferentForms/TeacherRecruitFormConfirm').then((module) => module.default));

const NewDesktopDownloadPage = asyncComponent(() =>
	import('./containers/NewDesktopDownloadPage/NewDesktopDownloadPage').then((module) => module.default));

const AImateDesktopDownloadPage = asyncComponent(() =>
	import('./containers/AImateDesktopDownloadPage/AImateDesktopDownloadPage').then((module) => module.default));

// const DesktopDownloadSelect = asyncComponent(() =>
//   import('./containers/DesktopDownloadPage/DesktopDownloadSelect').then(module => module.default));

// const DesktopDownloadPage = asyncComponent(() =>
//   import('./containers/DesktopDownloadPage/DesktopDownloadPage').then(module => module.default));

const StudentReportLogin = asyncComponent(() =>
	import('./containers/StudentReport/StudentReportLogin').then((module) => module.default));

const FormForAdult = asyncComponent(() =>
	import('./components/PlentyDifferentForms/FormForAdult').then((module) => module.default));

const DashboardUserAccountManage = asyncComponent(() =>
	import('./containers/NewStaffPage/DashboardUserAccountManage/DashboardUserAccountManage').then((module) => module.default));

const DocumentEditor = asyncComponent(() =>
	import('./containers/Document/DocumentEditor').then((module) => module.default));

const DocumentViewer = asyncComponent(() =>
	import('./components/Document/Viewer').then((module) => module.default));

const MicrobitEditorTest = asyncComponent(() =>
	import('./components/MicrobitEditor').then((module) => module.default));

const DemoStudentExercise = asyncComponent(() =>
	import('./containers/DemoPage/StudentExercise').then((module) => module.default));

const RegistrationRoute = asyncComponent(() =>
	import('./RegistrationRoute').then((module) => module.default));

const ParentHomePage = asyncComponent(() =>
	import('./containers/NewParentPage/ParentHomePage').then((module) => module.default));

const ParentFullReport = asyncComponent(() =>
	import('./containers/NewParentPage/ParentFullReport').then((module) => module.default));

const StudentGlobalExamPage = asyncComponent(() =>
	import('./containers/StudentGlobalExamPage/StudentGlobalExamPage').then((module) => module.default));
// Front-End document pages
const CodingbarClientDocument = asyncComponent(() =>
	import('./documents/CodingbarClientDocument').then((module) => module.default));

const EmailTemplate = asyncComponent(() =>
	import('./components/EmailTemplate/PreviewPage').then((module) => module.default));

const SendEmailTestPage = asyncComponent(() =>
	import('./components/EmailTemplate/SendEmailTestPage').then((module) => module.default));

const ImportExercisePage = asyncComponent(() =>
	import('./containers/ImportExercisePage/ImportExercisePage').then((module) => module.default));

const TranslationPage = asyncComponent(() =>
	import('./containers/TranslationPage/TranslationPage').then((module) => module.default));

const RealTimeRegistrationPage = asyncComponent(() =>
	import('./containers/RealTimeRegistrationPage/RealTimeRegistrationPage').then((module) => module.default));

const downloadPdfPage = asyncComponent(() =>
	import('./containers/downloadPdfPage/downloadPdfPage').then((module) => module.default));

const CSFCVerifyPage = asyncComponent(() =>
	import('./containers/CSFCVerifyPage/CSFCVerifyPage').then(module => module.default));

const CSFCExamPage = asyncComponent(() =>
	import('./containers/CSFCExamPage/CSFCExamPage').then(module => module.default));

const AiMateEditorPage = asyncComponent(() =>
	import('./containers/AiMateEditorPage/AiMateEditorPage').then((module) => module.default));

const AImateAccountPage = asyncComponent(() =>
	import('./containers/AImateAccountPage/AImateAccountPage').then((module) => module.default));

const AImatePlanPage = asyncComponent(() =>
	import('./containers/AImatePlanPage/AImatePlanPage').then((module) => module.default));

export default function () {
	return (
		<Router>
			<App>
				<Switch>
					{/* <RoleChecker roles={['teacher']} path="/teacher/course/:courseId" component={StudentCourse} /> */}
					{/* <RoleChecker roles={['teacher']} path="/teacher" component={TeacherPage} /> */}
					{/* <RoleChecker roles={['student']} path="/student" component={StudentPage} /> */}

					{/* <RoleChecker roles={['staff']} path="/staff/course/:courseId/lesson/:lessonId" component={LessonPage} /> */}
					{/* <RoleChecker roles={['staff']} path="/staff/course/:courseId" component={StaffCoursePage} /> */}
					{/* <RoleChecker roles={['staff']} path="/staff/course" component={StaffCourse} /> */}
					{/* <RoleChecker roles={['staff']} path="/staff/exercise/:exerciseId" component={StaffExercisePage} /> */}
					{/* <RoleChecker roles={['staff']} path="/staff/exercise" component={StaffExercises} /> */}
					{/* <RoleChecker roles={['staff']} path="/staff/user" component={StaffUser} /> */}
					<RoleChecker roles={['staff', 'teacher']} exact path="/course/manage" component={CourseManage} />
					<RoleChecker roles={['staff', 'teacher']} exact path="/demo/html" component={HtmlEditor} />

					{/* // new staff route */}
					<RoleChecker roles={['staff']} exact path="/manage/course" component={newStaffCoursePage} />
					<RoleChecker roles={['staff']} exact path="/manage/student" component={newStaffStudentPage} />
					<RoleChecker roles={['staff']} exact path="/manage/teacher" component={newStaffTeacherPage} />
					<RoleChecker roles={['staff']} exact path="/manage/staff" component={newStaffPage} />
					<RoleChecker roles={['staff']} exact path="/manage/dashboard" component={newStaffDashboardPage} />
					{/* <RoleChecker roles={['staff']} exact path="/manage/notification/:notificationId" component={newStaffNotificationPage} /> */}
					{/* <RoleChecker roles={['staff']} exact path="/manage/notification" component={newStaffNotificationPage} /> */}
					<RoleChecker roles={['staff']} exact path="/manage/information" component={newStaffInformationPage} />
					<RoleChecker roles={['staff']} exact path="/manage/formmanage" component={FormManage} />

					<RoleChecker roles={['staff']} exact path="/manage/registration" component={RegistrationManage} />
					<RoleChecker roles={['staff']} exact path="/manage/registration/download" component={RegistrationDownloadPage} />

					<RoleChecker roles={['staff']} exact path="/manage/registrationOld" component={RegistrationManageOld} />
					<RoleChecker roles={['staff']} exact path="/manage/registrationOld/download" component={RegistrationManageListPageOld} />
					<RoleChecker roles={['staff']} exact path="/manage/registrationV1/download" component={RegistrationManageListPageV1} />

					<RoleChecker roles={['staff']} exact path="/manage/couponmanage" component={CouponManage} />
					<RoleChecker roles={['staff']} exact path="/manage/useraccount" component={DashboardUserAccountManage} />
					<RoleChecker roles={['staff']} exact path="/manage/document/edit/:documentId" component={DocumentEditor} />
					<RoleChecker roles={['staff']} exact path="/manage/document/view/:documentId" component={DocumentViewer} />
					<RoleChecker roles={['staff', 'student']} exact path="/manage/documents" component={StaffDocumentsPage} />
					<RoleChecker roles={['staff']} exact path="/manage/announcements" component={AnnouncementManagePage} />
					<RoleChecker roles={['staff']} exact path="/manage/unitdomainmanage" component={UnitDomainManagePage} />
					<RoleChecker roles={['staff']} exact path="/manage/betaunitmanage" component={BetaUnitManagePage} />
					<RoleChecker roles={['staff']} exact path="/manage/unitPermissionsManage" component={UnitPermissionsManagePage} />
					<RoleChecker roles={['staff']} exact path="/manage/coursepackagemanage" component={CoursePackageManagePage} />
					<RoleChecker roles={['staff']} exact path="/manage/translationmanage" component={TranslationManagePage} />

					<RoleChecker roles={['staff']} exact path="/manage/crmaccountmanage" component={CrmAccountManage} />
					<RoleChecker roles={['staff']} exact path="/realtimeregistrationpage" component={RealTimeRegistrationPage} />
					<RoleChecker roles={['staff']} exact path="/manage/datasync" component={DataSyncPage} />
					<RoleChecker roles={['staff']} exact path="/manage/internalmailmanage" component={InternalMailManage} />

					<RoleChecker roles={['staff']} exact path="/manage/crm-student" component={StudentCRM} />
					<RoleChecker roles={['staff']} exact path="/manage/crm" component={CRMPage} />
					<RoleChecker roles={['staff']} exact path="/manage/tools" component={ToolsPage} />
					<RoleChecker roles={['staff']} exact path="/manage/teacherSchedule" component={TeacherSchedulePage} />


					{/* // Admin route */}
					<RoleChecker roles={['admin']} exact path="/admin/student" component={newStaffStudentPage} />
					<RoleChecker roles={['admin']} exact path="/admin/teacher" component={newStaffTeacherPage} />
					<RoleChecker roles={['admin']} exact path="/admin/staff" component={newStaffPage} />
					{/* <RoleChecker roles={['admin']} exact path="/admin/notification/:notificationId" component={newStaffNotificationPage} /> */}
					{/* <RoleChecker roles={['admin']} exact path="/admin/notification" component={newStaffNotificationPage} /> */}
					<RoleChecker roles={['admin']} exact path="/admin/information" component={newStaffInformationPage} />

					{/* // new teacher route */}
					<RoleChecker roles={['teacher']} path="/question/:courseId/:creatorId/:questionId" component={TeacherQuestionPage} />
					<RoleChecker roles={['teacher']} path="/teacher/question-label/:courseId/:creatorId/:questionId" component={TeacherQuestionsLabel} />
					<RoleChecker roles={['teacher']} path="/teacher/exercise-label" component={ExerciseErrorLabel} />
					<RoleChecker roles={['teacher']} exact path="/teacher/course/:courseId/test/:testId/exercise/:exerciseId" component={TeacherTestMainPage} />
					<RoleChecker roles={['teacher']} exact path="/teacher/course/:courseId/lesson/:lessonId/exercise/:exerciseId" component={TeacherExercise} />
					<RoleChecker roles={['teacher']} exact path="/teacher/course/:courseId/lesson/:lessonId" component={TeacherCourseLessonDetailsPage} />
					<RoleChecker roles={['teacher']} exact sensitive path="/teacher/course/:courseId/lesson" component={TeacherCourseLessonPageV3} />
					<RoleChecker roles={['teacher']} exact sensitive path="/teacher/Course/:courseId/lesson" component={TeacherCourseLessonPageV2} />
					<RoleChecker roles={['teacher']} exact path="/teacher/courseOld/:courseId/lesson" component={TeacherCourseLessonPageOld} />
					<RoleChecker roles={['teacher']} exact path="/teacher/course/:courseId/student/:studentId" component={TeacherCourseStudentDetailPage} />
					<RoleChecker roles={['teacher']} exact path="/teacher/course/:courseId/studentOld/:studentId" component={TeacherCourseStudentDetailPageOld} />
					<RoleChecker roles={['teacher']} exact path="/teacher/course/:courseId/student" component={TeacherCourseStudentPage} />
					<RoleChecker roles={['teacher']} exact path="/teacher/course/:courseId/live" component={TeacherCourseLivePage} />
					<RoleChecker roles={['teacher']} exact path="/teacher/course/:courseId/test" component={TeacherCourseTestPage} />
					<RoleChecker roles={['teacher']} exact path="/teacher/course/:courseId/test/:testId" component={TeacherCourseTestDetailsPage} />
					<RoleChecker roles={['teacher']} exact path="/teacher/course/:courseId/test/Old" component={TeacherCourseTestPageOld} />
					<RoleChecker roles={['teacher']} exact path="/teacher/course/:courseId/score" component={TeacherCourseScorePage} />
					<RoleChecker roles={['teacher']} exact sensitive path="/teacher/course/:courseId" component={TeacherCourseLessonPageV3} />
					<RoleChecker roles={['teacher']} exact sensitive path="/teacher/Course/:courseId" component={TeacherCourseLessonPageV2} />
					{/* <RoleChecker roles={['teacher']} exact path="/teacher/resource/test/:testId" component={TeacherTestDetailsPage} /> */}
					<RoleChecker roles={['teacher']} exact path="/teacher/course" component={TeacherCoursePage} />
					<RoleChecker roles={['teacher']} exact path="/teacher/student" component={newStaffStudentPage} />
					<RoleChecker roles={['teacher']} exact path="/teacher/material" component={MaterialManagePage} />
					<RoleChecker roles={['teacher']} exact path="/teacher/resourceOld" component={TeacherResourcePageOld} />
					<RoleChecker roles={['teacher']} exact path="/teacher/resource" component={TeacherResourcePage} />
					<RoleChecker roles={['teacher']} exact path="/teacher/resource-shared" component={TeacherResourcePage} />
					<RoleChecker roles={['teacher']} exact path="/teacher/tutorial" component={TeacherTutorialPage} />
					<RoleChecker roles={['teacher']} exact path="/teacher/notification/:notificationId" component={TeacherNotificationPage} />
					<RoleChecker roles={['teacher']} exact path="/teacher/notification" component={TeacherNotificationPage} />
					<RoleChecker roles={['teacher']} exact path="/teacher/information" component={TeacherInformationPage} />
					<RoleChecker roles={['teacher']} exact path="/teacher" component={TeacherCoursePage} />
					<RoleChecker roles={['teacher']} exact path="/question" component={TeacherQuestionPage} />
					<RoleChecker roles={['teacher']} exact path="/teacher/question" component={TeacherQuestionPage} />
					<RoleChecker roles={['teacher']} exact path="/teacher/question-label" component={TeacherQuestionsLabel} />

					{/* // new student route */}
					<RoleChecker roles={['student']} path="/student/question/:courseId/:creatorId/:questionId" component={StudentQuestionsPage} />
					<RoleChecker roles={['student']} exact path="/student/course/:courseId/test/:testId/exerciseOld/:exerciseId" component={StudentTestMainPageOld} />
					<RoleChecker roles={['student']} exact path="/student/course/:courseId/test/:testId/exercise/:exerciseId" component={ExerciseEditorPage} />
					<RoleChecker roles={['student']} exact path="/student/course/:courseId/review/:testId/exercise/:exerciseId" component={ExerciseEditorPage} />
					<RoleChecker roles={['student']} exact path="/student/course/:courseId/lesson/:lessonId/exerciseOld/:exerciseId" component={StudentExercise} />
					<RoleChecker roles={['student']} exact path="/student/course/:courseId/lesson/:lessonId/exercise/:exerciseId" component={ExerciseEditorPage} />
					<RoleChecker roles={['student']} exact path="/student/course/:courseId/lesson/:lessonId" component={StudentCourseLessonDetailsPage} />
					<RoleChecker roles={['student']} exact path="/student/course/:courseId/lesson" component={StudentCourseLessonPage} />
					<RoleChecker roles={['student']} exact path="/student/course/:courseId/exercise" component={StudentCourseExercisePage} />
					<RoleChecker roles={['student']} exact path="/student/course/:courseId/live" component={StudentCourseLivePage} />
					<RoleChecker roles={['student']} exact path="/student/course/:courseId/test/Old" component={StudentCourseTestPageOld} />
					<RoleChecker roles={['student']} exact path="/student/course/:courseId/test" component={StudentCourseTestPage} />
					<RoleChecker roles={['student']} exact path="/student/course/:courseId/test/:testId/Old" component={StudentCourseTestDetailsPageOld} />
					<RoleChecker roles={['student']} exact path="/student/course/:courseId/test/:testId" component={StudentCourseTestDetailsPage} />
					<RoleChecker roles={['student']} exact path="/student/course/:courseId" component={StudentCourseLessonPage} />
					<RoleChecker roles={['student']} exact path="/student/note" component={StudentNotePage} />
					<RoleChecker roles={['student']} exact path="/student/challenge" component={StudentChallengePage} />
					<RoleChecker roles={['student']} exact path="/student/editor" component={StudentEditorPage} />
					<RoleChecker roles={['student']} exact path="/student/notification/:notificationId" component={StudentNotificationPage} />
					<RoleChecker roles={['student']} exact path="/student/notification" component={StudentNotificationPage} />
					<RoleChecker roles={['student']} exact path="/student/information" component={StudentInformationPage} />
					<RoleChecker roles={['student']} exact path="/student/course" component={StudentCoursePage} />
					<RoleChecker roles={['student']} exact path="/student/live" component={StudentLivePage} />
					<RoleChecker roles={['student']} exact path="/student/result" component={StudentStudyResultPage} />
					<RoleChecker roles={['student']} exact path="/student/result/:courseId" component={StudentStudyResultCoursePage} />
					<RoleChecker roles={['student']} exact path="/student/globalexam" component={StudentGlobalExamPage} />
					<RoleChecker roles={['student']} exact path="/student/question" component={StudentQuestionsPage} />
					<RoleChecker roles={['student']} exact path="/student" component={StudentCoursePage} />

					<RoleChecker roles={['student']} exact path="/microbit-editor-test" component={MicrobitEditorTest} />

					<RoleChecker roles={['student']} exact path="/AImate/editor" sensitive={false} component={AiMateEditorPage} />
					<RoleChecker roles={['student']} exact path="/AImate/account" component={AImateAccountPage} />
					<RoleChecker roles={['student']} exact path="/AImate/plan" component={AImatePlanPage} />

					<RoleChecker exact roles={['student']} path="/csfcasia/exam" component={CSFCExamPage} />
					{/* <RoleChecker roles={['student']} path="/student/exam/:courseId/exercise/:exerciseId" component={StudentGlobalTest} /> */}
					{/* <RoleChecker roles={['student']} path="/student/examOld/:courseId/exercise/:exerciseId" component={StudentGlobalTestOld} /> */}

					<RoleChecker roles={['demo']} isDemo exact path="/demo/student/course/lesson/exercise/:exerciseId" component={DemoStudentExercise} />

					<RoleChecker exact path="/demo" component={DemoPage} />

					<Route exact path="/parent/index" component={ParentHomePage} />
					<Route exact path="/parent/report" component={ParentFullReport} />

					<Route exact path="/teacher/importexercise" component={ImportExercisePage} />

					<Route path="/policy" component={PolicyPage} />
					<Route path="/requestResetPassword" component={RequestResetPasswordPage} />
					<Route path="/resetPassword" component={ResetPasswordPage} />
					<Route exact path="/login" component={Login} />
					{/* exact 確保判斷路徑要完全一樣才算，才不會讀取到 login 就沒辦法判斷下一層 exam */}
					<Route exact path="/exam" component={LoginExam} />
					<Route exact path="/free_trial" component={LoginTrial} />
					<Route exact path="/AImate" sensitive={false} component={Login} />
					<Route exact path="/AImate/signup" sensitive={false} component={Login} />
					<Route exact path="/AImate/desktop" component={AImateDesktopDownloadPage} />

					<Route exact path="/free_trial_NPBN" component={freeTrialNPBN} />

					<Route exact path="/schoolTrail/verify" component={SchoolTrailVerifyPage} />

					<Route exact path="/loginRedirect" component={LoginRedirectPage} />
					<Route exact path="/csfcasia" component={LoginCSFCPage} />
					<Route exact path="/csfcasia/verify" component={CSFCVerifyPage} />

					<Route path="/signup" component={SignUpPage} />
					<Route path="/verify/email-sent" component={EmailSentPage} />
					<Route path="/verify/email-unverified" component={EmailUnverifiedPage} />
					<Route path="/verify/fill-info" component={FillInfoPage} />
					<Route path="/registration" component={RegistrationRoute} />
					{/* <Route path="/register/" component={RegistrationRoute}/> */}
					<Route exact path="/register/course/adult/:formId/:test" component={FormForAdult} />
					<Route exact path="/register/course/adult/:formId" component={FormForAdult} />
					<Route exact path="/register/course/regular/:formId/:test" component={FormForAdult} />
					<Route exact path="/register/course/regular/:formId" component={FormForAdult} />
					<Route exact path="/register/course/:formId" component={FormForWorkShopAndCamp} />
					<Route exact path="/register/course/:formId/:test" component={FormForWorkShopAndCamp} />
					<Route exact path="/register/result/:formId" component={ResultPage} />
					<Route exact path="/register/result/:formId/:test" component={ResultPage} />
					{/* <Route exact path="/training/teacher/:formId" component={TeacherRecruitForm} /> */}
					<Route exact path="/training/teacher/:formId/confirm" component={TeacherRecruitFormConfirm} />
					<Route exact path="/training/teacher/:formId/confirm/:test" component={TeacherRecruitFormConfirm} />
					<Route exact path="/desktop/select" component={NewDesktopDownloadPage} />
					{/* <Route exact path="/desktop/select/:version/:os/:feature/" component={DesktopDownloadSelect} />
					<Route exact path="/desktop/select/:os/:feature/" component={DesktopDownloadSelect} />
					<Route exact path="/desktop/select" component={DesktopDownloadSelect} />
					<Route exact path="/desktop/download/:os/:feature/" component={DesktopDownloadPage} />
					<Route exact path="/desktop/download/:version/:os/:feature/" component={DesktopDownloadPage} /> */}
					<Route exact path="/student/report/:courseId/:studentId" component={StudentReportLogin} />
					<Route exact path="/student/report" component={StudentReportLogin} />
					<Route exact path="/document/view/:documentId" component={DocumentViewer} />
					<Route exact path="/email/template" component={EmailTemplate} />
					<Route exact path="/email/sendEmail" component={SendEmailTestPage} />
					<Route exact path="/downloadPdf/type/:type/:encode" component={downloadPdfPage} />

					<Route exact path="/translation" component={TranslationPage} />
					<Route exact path="/translation/:courseId" component={TranslationPage} />

					{/* Front-End document page */}
					<Route exact path="/codingbar-client-document" component={CodingbarClientDocument} />

					<Redirect from="*" to="/login" />
				</Switch>
			</App>
		</Router>
	);
}
