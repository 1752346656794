import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import i18n from 'lang/i18n';
import { ActionModal } from 'components';
import ToastQueue from 'components/common/Toast/ToastQueue';
import {
	initAction,
	openPlayGround,
	closePlayGround,
	setOnline,
	setOffline,
	addToast,
	clearCurrentSubmitting,
	setCSFCUser,
	setAiMateSubscriptionPlans,
	setAiMateUserPlan,
} from 'redux/reducers/app';

import Header from './Header/Header';
import InProgress from './InProgress';

import './App.scss';
import PlaygroundFrame from '../Playground/PlaygroundFrame';
import { isElectron, removeConfirmCloseListener } from '../../shared/util/utils';
import { fetchAllCoursesIncludesStudent } from 'shared/api/Courses';
import { fetchSchoolUnitDomainList } from 'shared/api/UnitDomain';
import { checkIsFeatureTrialUnit, isCSFC, isAiMate } from 'shared/helper/permission';
import { getCSFCUser, getCSFCUserUidCache, } from 'shared/api/CSFCRegistration';
import { getUnitPermissions, } from 'shared/api/Units';

import { codingbarApi } from 'codingbar-api';
import ErrorHandler from './ErrorHandler';

import { getCurrentDesktopVersion } from '../../settings';
import MultiLoginAlert from 'components/MultiLoginAlert/MultiLoginAlert';
import StudentTestAlert from 'components/StudentTestAlert/StudentTestAlert';
import { compose } from 'recompose';
import CodingbarThemeProvider from '../../CodingbarThemeProvider';
import BodyContent from './BodyContent/BodyContent';
import ZoomManager from '../zoom/ZoomManager';
import queryString from 'query-string';
import { isEmpty } from 'lodash';
// ---- FOR BETA ----
import { setBetaUser, removeBetaUser, setSchoolDomainUser, unsetSchoolDomainUser, setUnitPermissions } from 'redux/reducers/app';
import { getCodingBarBetaAccessUnitList } from 'shared/api/BetaUnit';
import { fetchAImateSubscriptionPlans, getAImateUserCurrentSubscriptionPlan } from 'shared/api/aiMate';
import { 
	BETA_USER_LIST,
	SCHOOL_DOMAIN_LIST,
	SET_CLOSE_TOAST,
	SET_FEATURE_TRIAL_UNIT,
	REMOVE_FEATURE_TRIAL_UNIT,
} from 'redux/constants/app';
import csfcFavicon from 'design/images/header/csfc_favicon.png';

const mapStateToProps = (state) => {
	const app = state.app;
	return {
		initSuc: app.get('initSuc'),
		user: app.get('user'),
		csfcUser: app.get('csfcUser'),
		multiLoginAlertShow: app.get('multiLoginAlertShow'),
	};
};
const mapDispatchToProps = {
	initAction,
};


@connect((state) => {
	const app = state.app.toJS();

	return {
		showPlayGround: app.showPlayGround,
		onlineStatus: app.onlineStatus,
		isSchoolDomainUser: app.isSchoolDomainUser,
		schoolDomainList: app.schoolDomainList,
		AImateSubscriptionPlans: app.AImateSubscriptionPlans,
		// ---- FOR BETA ----
		// betaUserList: app.betaUserList,
	};
})
class App extends React.Component {
	static propTypes = {
		initSuc: PropTypes.bool.isRequired,
		initAction: PropTypes.func.isRequired,
		user: PropTypes.object,
	};

	constructor(props) {
		super(props);

		this.state = {
			showZoom: false,
			selfKernel: undefined,
			progressDisabled: this.isProgressDisabled(props),
			headerDisabled: this.isHeaderDisabled(props),
			isShowToastAtBottom: this.isShowToastAtBottom(props),
			showVersionUpgrade: false,
			playgroundFullScreen: false,
			videoConferenceInfos: undefined,
			showTestModal: false,
			testRoute: undefined,
			testAlertData: undefined,
		};
		localStorage.removeItem('alreadyShowVersionUpgrade');
		this.onError = this.onError.bind(this);

		window.onerror = this.onWindowError;
	}

	componentDidUpdate = (prevProps) => {
		const path = this.props.location && this.props.location.pathname ? this.props.location.pathname : '';
		const prevPath = prevProps.location.pathname || '';
		const pathsToCheck = ['parent', 'desktop', 'login', 'exam', 'free_trial', 'csfcasia', 'aimate'];
		const shouldCheckHeader = pathsToCheck.some((el) => {
			if (el === 'aimate') {
				return path.toLowerCase().includes(el) || prevPath.toLowerCase().includes(el);
			}
			return path.includes(el) || prevPath.includes(el);
		});

		if (prevProps.location.pathname !== path && shouldCheckHeader) {
			this.setState({
				headerDisabled: this.isHeaderDisabled(this.props),
			});
		}
		if (prevProps.location.pathname !== path) {
			this.setState({
				isShowToastAtBottom: this.isShowToastAtBottom(this.props),
			});
		}

		if (this.props.user && this.props.schoolDomainList && this.props.user !== prevProps.user && !prevProps.user) {
			this.checkingForSchoolDomain(this.props.user.get('unit'), this.props.user.get('units'));
			
		}

		// 切換帳號 csfc user
		if (this.props.user && this.props.user !== prevProps.user && !prevProps.user && isCSFC() && getCSFCUserUidCache() && !this.props.csfcUser) {
			getCSFCUser(getCSFCUserUidCache()).then((res) => {
				this.props.dispatch(setCSFCUser(res));
			});
		}
		// ---- FOR UNIT PERMISSION----
		if (this.props.user && this.props.user !== prevProps.user && !prevProps.user) {
			getUnitPermissions(this.props.user.get('unit')).then((res) => {
				this.props.dispatch(setUnitPermissions(res));
			});
		}

		if (this.props.user && this.props.user !== prevProps.user && !prevProps.user && this.props.AImateSubscriptionPlans) {
			const uid = this.props.user.get('uid');
			getAImateUserCurrentSubscriptionPlan(uid, this.props.AImateSubscriptionPlans).then((res) => {
				console.log('SSS>>>>variabgetAImateUserCurrentSubscriptionPlanle:', res);
				this.props.dispatch(setAiMateUserPlan(res));
			});
		}

		// ---- FOR BETA ----
		/*
		if (this.props.user && this.props.betaUserList && this.props.user !== prevProps.user) {
			this.checkingForBeta(this.props.user.get('unit'), this.props.user.get('units'));
		}
		*/

		// ---- FeatureTrialUnit (beta 過渡期)----
		/*
		if (this.props.user && this.props.user !== prevProps.user) {
			const isFeatureTrialUnit = checkIsFeatureTrialUnit(this.props.user.get('unit'));
			if (isFeatureTrialUnit) {
				this.props.dispatch({ type: SET_FEATURE_TRIAL_UNIT });
			} else {
				this.props.dispatch({ type: REMOVE_FEATURE_TRIAL_UNIT });
			}
		}
		*/
	};

	isProgressDisabled(props) {
		const path = props.location && props.location.pathname ? props.location.pathname : undefined;
		if (!path) {
			return false;
		}

		if (path.indexOf('/desktop/') !== -1) {
			return true;
		}

		if (path.indexOf('/register/') !== -1) {
			return true;
		}

		if (path.indexOf('/training/') !== -1) {
			return true;
		}

		// if (path.indexOf("/signup") !== -1) {
		//   return true;
		// }

		// if (path.indexOf("/login") !== -1) {
		//   return true;
		// }

		return false;
	}

	setDynamicFaviconAndTitle = () => {
		if (isCSFC() && document.title !== 'CSFC 資訊科學能力檢定') {
			console.log('setDynamicFaviconAndTitle:');
			const faviconLink = document.querySelector("link[href='/favicon.ico']");
			document.title = 'CSFC 資訊科學能力檢定';
			faviconLink.href = csfcFavicon;
		}
	}

	isHeaderDisabled = (props) => {
		//記得去改上面的 componentDidUpdate 復原 Header！
		const path = props.location && props.location.pathname ? props.location.pathname : undefined;
		if (!path) return false;
		if (path.indexOf('/desktop/') !== -1) return true;
		if (path.indexOf('/parent') !== -1) return true;
		if (path.indexOf('/codingbar-client-document') !== -1) return true;
		if (path === '/login') return true;
		if (path === '/login' || path === '/login/' || path.includes('/login')) return true;
		if (path === '/exam' || path === '/exam/' || path.includes('/exam?')) return true;
		if (path === '/free_trial' || path === '/free_trial/' || path.includes('/free_trial?')) return true;
		if (path === '/csfcasia') return true; // csfcasia login page
		if (path.toLowerCase() === '/aimate' || path.toLowerCase() === '/aimate/') return true; // aiMate login page, desktop path is '/aimate/'
		if (path.toLowerCase() === '/aimate/signup') return true; // aiMate login page
		if (path.toLowerCase() === '/aimate/desktop') return true; // aiMate login page
		return false;
	};

	isShowToastAtBottom = (props) => {
		const currentUrl = props.location && props.location.pathname ? props.location.pathname : undefined;

		if (!currentUrl) return false;

		const studentExerciseUrl = ['/student', '/course', '/lesson', '/exercise'];
		const studentTestUrl = ['/student', '/course', '/test', '/exercise'];
		const globalExamUrl = ['/student', '/exam', '/exercise'];

		return (
			studentExerciseUrl.every((el) => currentUrl.includes(el)) ||
			studentTestUrl.every((el) => currentUrl.includes(el)) ||
			globalExamUrl.every((el) => currentUrl.includes(el))
		);
	};

	onError(error, scriptURI, lineNumber, columnNumber, info) {
		const bug = {
			error: error,
			info: {
				scriptURI,
				lineNumber,
				columnNumber,
				info,
			},
		};

		console.error('onError#', bug);

		this.setState({
			bug: bug,
			showError: true,
		});
		return true;
	}

	onWindowError(error, scriptURI, lineNumber, columnNumber, info) {
		const bug = {
			error: error,
			info: {
				scriptURI,
				lineNumber,
				columnNumber,
				info,
			},
		};

		console.error('onWindowError#error:', error);
		console.error('onWindowError#info:', info);
		// codingbarApi.getCoreService().createData("bugs", {
		//   from: 'client',
		//   error: error,
		//   info: info,
		//   user: user,
		//   url: window.location.href
		// });

		// if (!window.location.href.includes("localhost")) {
		//
		//   return true;
		// }
	}

	componentDidMount = () => {
		const { dispatch } = this.props;
		this.props.initAction();
		this.checkingForBenq();
		console.log('App #componentDidMount #getCodingBarBetaAccessUnitList');

		// ---- FOR BETA ----
		/*
		getCodingBarBetaAccessUnitList().then((res) => {
			dispatch({ type: BETA_USER_LIST, betaUserList: res });
		});
		*/

		// reload 會觸發
		if (isCSFC() && getCSFCUserUidCache()) {
			getCSFCUser(getCSFCUserUidCache()).then((res) => {
				this.props.dispatch(setCSFCUser(res));
			});
		}
		this.setDynamicFaviconAndTitle()

		fetchAImateSubscriptionPlans().then((res) => {
			const plans = res.result.sort((a, b) => {
				return  a.monthlyPrice - b.monthlyPrice;
			});
			dispatch(setAiMateSubscriptionPlans(plans));
		});

		fetchSchoolUnitDomainList().then((res) => {
			dispatch({ type: SCHOOL_DOMAIN_LIST, schoolDomainList: res });
		});

		if (!window.location.href.includes('localhost:8080')) {
			if (window.navigator.onLine) {
				//change default to be true if online
				this.toggleOnlineStatus();
			}

			window.addEventListener('offline', this.toggleOnlineStatus);
			window.addEventListener('online', this.toggleOnlineStatus);
		} else {
			dispatch(setOnline());
		}
	};

	componentWillReceiveProps(nextProps) {
		try {
			this.checkDesktopVersion(nextProps);
			this.setupSpeedListener(nextProps);
			// this.setupNotificationService(nextProps);
		} catch (err) {
			console.error(err);
		}
	}

	componentWillUnmount = () => {
		if (!window.location.href.includes('localhost:8080')) {
			window.removeEventListener('offline', this.toggleOnlineStatus);
			window.removeEventListener('online', this.toggleOnlineStatus);
		}
	};

	checkingForSchoolDomain = (userUnit, userUnits) => {
		const { dispatch, schoolDomainList } = this.props;

		if (!userUnit && !userUnits && !schoolDomainList) return;
		const unitFilterList = schoolDomainList
			? schoolDomainList.filter((obj) => obj.unit.toLowerCase() === userUnit.toLowerCase())
			: [];
		// 這版先不用管多重單位
		// const unitsFilterList = (betaUserList) ? betaUserList.filter(obj=>userUnits.some(item => item.toLowerCase() === obj.unit.toLowerCase())) : [];
		// if (isEmpty(unitFilterList) && isEmpty(unitsFilterList)) {
		if (isEmpty(unitFilterList)) {
			dispatch(setSchoolDomainUser());
		} else {
			dispatch(unsetSchoolDomainUser());
		}
	};

	// ---- FOR BETA ----
	/*
	checkingForBeta = (userUnit, userUnits) => {
		const { dispatch, betaUserList } = this.props;
		if (!userUnit && !userUnits && !betaUserList) return;
		console.log('APP #checkingForBeta:');
		const unitFilterList = betaUserList
			? betaUserList.filter((obj) => obj.unit.toLowerCase() === userUnit.toLowerCase())
			: [];
		// 這版先不用管多重單位
		// const unitsFilterList = (betaUserList) ? betaUserList.filter(obj=>userUnits.some(item => item.toLowerCase() === obj.unit.toLowerCase())) : [];
		// if (isEmpty(unitFilterList) && isEmpty(unitsFilterList)) {
		if (isEmpty(unitFilterList)) {
			dispatch(removeBetaUser());
			localStorage.removeItem('SYS');
		} else {
			dispatch(setBetaUser());
			localStorage.setItem('SYS', 'Beta');
		}
	};
	*/

	setupSpeedListener(nextProps) {
		const { dispatch } = this.props;
		const { initSuc } = nextProps;

		if (!initSuc || initSuc === this.props.initSuc) {
			return;
		}

		codingbarApi.getCoreService().setSpeedListener({
			usageUploadInterval: 60000, // every minute
			threshold: 10000, // if postJson takes more than 10 sec
			onSlowQuery: (info) => {
				console.log('onSlowQuery', info);
				dispatch(addToast({
					toastType: 'alert',
					toastText: i18n.t('common.slowQuery'),
					isShowAtBottom: this.state.isShowToastAtBottom,
				}));
			},
		});
	}

	setupNotificationService = (nextProps) => {
		const { initSuc } = nextProps;
		const user = this.getUser();
		const host = this;

		//學生偵測老師發起考試事件
		codingbarApi.getNotificationService().subscribeEventNotify('all', 'startTest', {
			onChanged(data) {
				if (data[0] && host.checkBetweenTime(data[0].sendTime)) {
					//只有第一次收到才需要處理資料
					if (user.role === data[0].role) {
						fetchAllCoursesIncludesStudent([user.uid])
						.then((courses) => {
							const findCourse = courses.filter((course) => course.id === data[0].testCourseId);

							if (findCourse.length !== 0) {
								host.setState({
									showTestModal: true,
									testRoute: data[0].testRoute,
									testAlertData: {
										modalTitle: i18n.t('component.StartTestAlertModal.title'),
										modalContent: i18n.t('component.StartTestAlertModal.content'),
										modalButtonWord: i18n.t('testPreview.gototest'),
									},
								});
							}
						});
					}
				}
			},
		});

		//學生偵測老師終止考試事件
		codingbarApi.getNotificationService().subscribeEventNotify('all', 'endTest', {
			onChanged(data) {
				if (data[0] && host.checkBetweenTime(data[0].sendTime)) {
					//只有第一次收到才需要處理資料
					if (user.role === data[0].role) {
						fetchAllCoursesIncludesStudent([user.uid]).then((courses) => {
							const findCourse = courses.filter((course) => course.id === data[0].testCourseId);

							if (findCourse.length !== 0) {
								const isTesting = window.location.href.includes('test') && window.location.href.includes('exercise');

								if (!isTesting) {
									this.props.dispatch(addToast({
										toastType: 'success',
										toastText: i18n.t('component.studentNotification.endTest'),
										actionText: i18n.t('loadingProcessBar.endExamDone.ok'),
										onClickAction: () => { this.goToViewTestScore(data[0].testCourseId); },
									}));
								} else {
									removeConfirmCloseListener();
									host.setState({
										showTestModal: true,
										testRoute: data[0].testRoute,
										testAlertData: {
											modalTitle: i18n.t('component.EndTestAlertModal.title'),
											modalContent: i18n.t('component.EndTestAlertModal.content'),
											modalButtonWord: i18n.t('common.leave'),
										},
									});
								}
							}
						});
					}
				}
			},
		});
	};
	
	checkBetweenTime = (timestamp) => {
		if (timestamp - 5000 <= Date.now() && Date.now() <= timestamp + 5000) {
			//差距 5s 以上視為網路環境不好, 應跳 網速過慢 toast
			return true;
		}
		return false;
	};
	goToViewTestScore = (testId) => {
		window.location.href = `/student/course/${testId}/test/beta`;
	};
	closeTestModal = () => {
		this.setState({
			showTestModal: false,
		});
	};
	checkingForBenq() {
		// console.log("location", this.props.location)
		const brand = queryString.parse(this.props.location.search).brand;
		// console.log("brand#", brand)
		if (brand) {
			localStorage.setItem('brand', `${brand}`);
		}
	}

	componentDidCatch(error, info) {
		this.onError('global error', '', '', '', error);
	}

	// Header Functions: Playground
	// showPlayground = () => {
	//   this.setState({
	//     showPlayground: true
	//   })
	// }

	togglePlayground = () => {
		const { dispatch } = this.props;

		if (this.props.showPlayGround === true) {
			dispatch(closePlayGround());
			dispatch(clearCurrentSubmitting());
		} else {
			dispatch(openPlayGround());
		}
	};

	setZoomVisible(visible, videoConferenceInfos) {
		// console.log("setZoomVisible#videoConferenceInfos", videoConferenceInfos);
		this.setState({
			showZoom: visible,
			videoConferenceInfos,
		});
	}

	toggleZoom = (videoConferenceInfos, isClicked) => {
		// console.log("toggleZoom#videoConferenceInfos", videoConferenceInfos);
		this.setZoomVisible(isClicked ? !this.state.showZoom : this.state.showZoom, videoConferenceInfos);
	};

	toggleOnlineStatus = () => {
		const { dispatch } = this.props;

		if (this.props.onlineStatus === true) {
			sessionStorage.removeItem('isReload');
			dispatch(setOffline());
		} else {
			dispatch(setOnline());
			const isReload = sessionStorage.getItem('isReload');
			if (!isReload) {
				sessionStorage.setItem('isReload', true);
				window.location.reload(true);
			}
		}
	};

	getUser() {
		try {
			return codingbarApi.getAuthService().getUser();
		} catch (err) {
			console.log(err);
		}
		return undefined;
	}

	checkDesktopVersion(props) {
		// console.log("checkDesktopVersion#props:", props);

		if (!isElectron()) {
			// console.log("App# not desktop version!");
			return;
		}

		if (localStorage.getItem('alreadyShowVersionUpgrade') === 'true') {
			// console.log("App# alreadyShowVersionUpgrade");
			return;
		}

		const { initSuc } = props;

		// console.log("checkDesktopVersion#isElectron:", isElectron());
		// console.log("checkDesktopVersion#initSuc:", initSuc);

		if (false && initSuc) {
			const desktopVersion = localStorage.getItem('desktopVersion') ? localStorage.getItem('desktopVersion') : 'v1.5';
			const currentDesktopVersion = getCurrentDesktopVersion();
			// console.log("checkDesktopVersion# user desktop version:", desktopVersion, 'current desktop version: ', currentDesktopVersion);
			if (!desktopVersion || !desktopVersion.includes(currentDesktopVersion)) {
				this.setState({
					desktopVersion: desktopVersion,
					showVersionUpgrade: true,
				});
			}
		}
	}

	checkDesktop() {
		const user = this.getUser();
		// console.log("checkDesktop# user:", user);
		if (!user || !user.uid) {
			this.setState({
				selfKernel: undefined,
			});
		}

		if (!isElectron()) {
			console.log('App# not electron!');
			return;
		}

		if (this._timerInstance) {
			clearInterval(this._timerInstance);
			this._timerInstance = undefined;
		}

		this._timerInstance = setInterval(() => {
			// console.log("App# checkDesktop()")
			const user = this.getUser();
			if (user && user.uid) {
				codingbarApi
					.getCourseService()
					.getAvailableKernels()
					.then((kernels) => {
						const selfKernel = kernels.find((k) => k.name === user.uid);
						if (selfKernel) {
							this.setState({
								selfKernel: selfKernel,
							});
						} else {
							this.setState({
								selfKernel: undefined,
							});
						}
					});
			} else {
				this.setState({
					selfKernel: undefined,
				});
			}
		}, 10000);
	}

	handleFullScreen = (e) => {
		this.setState({ playgroundFullScreen: !this.state.playgroundFullScreen }, () => {
			console.log('fullscreen');
		});
	};

	getCloudPage() {
		const { initSuc, showPlayGround } = this.props;
		const { showZoom, videoConferenceInfos } = this.state;
		const user = JSON.parse(localStorage.getItem('user'));

		// console.log("app#videoConferenceInfos", videoConferenceInfos);
		// console.log("app#showZoom", showZoom);

		return (
			<div>
				{initSuc && user && showPlayGround && <PlaygroundFrame hidePlayground={this.togglePlayground} />}
				{initSuc || this.state.progressDisabled ? this.props.children : <InProgress />}
				{initSuc && user && showZoom && (
					<ZoomManager
						setVisible={this.setZoomVisible.bind(this)}
						videoConferenceInfos={videoConferenceInfos}
						visible={showZoom}
					/>
				)}
			</div>
		);
	}

	getDesktopPage() {
		return (
			<div style={{ fontSize: '36pt', textAlign: 'center', marginTop: '100px' }}>
				正在執行中
				<div style={{ fontSize: '18pt', marginTop: '100px' }}>
					請打開瀏覽器，輸入 <a href="https://app.codingbar.ai">https://app.codingbar.ai</a> 開始快樂 coding 吧!
				</div>
			</div>
		);
	}

	closeVersionCheckBox = () => {
		localStorage.setItem('alreadyShowVersionUpgrade', 'true');
		this.setState({
			showVersionUpgrade: false,
		});
	}

	renderVersionBox() {
		const confirmMessage = (
			<div>
				<div>您使用版本({this.state.desktopVersion})太舊，目前最新的版本是 v1.6</div>
				<div>請下載最新的 PC 版 CodingBar，得到最好的支援!</div>
				下載網址:{' '}
				<a href={'https://app.codingbar.ai/desktop/select/1.6/mac/pm'} target={'_blank'}>
					https://app.codingbar.ai/desktop/select/1.6/mac/pm
				</a>
			</div>
		);
		return (
			<ActionModal
				type="confirmation"
				isOpen={this.state.showVersionUpgrade}
				onConfirm={this.closeVersionCheckBox}
				modalTitle="版本更新"
				isShowClose={false}
				isHideCancelBtn
				modalBody={confirmMessage}
				confirmButtonText="繼續"
			/>
		);
	}

	closeErrorHandler() {
		this.setState({
			bug: undefined,
			showError: false,
		});

		if ((isAiMate('route') && isAiMate('workspace')) || isAiMate('aiMateOnly')) {
			this.props.history.push(`/AImate`);
			return;
		}

		if (isCSFC()) {
			this.props.history.push(`/csfcasia`);
			return;
		}
		this.props.history.push(`/`);
	}

	renderErrorHandler() {
		if (!this.state.showError || !this.state.bug) {
			return;
		}

		return (
			<div>
				<ErrorHandler bug={this.state.bug} close={this.closeErrorHandler.bind(this)} />
			</div>
		);
	}

	render() {
		return (
			<CodingbarThemeProvider>
				<link href="https://fonts.googleapis.com/css?family=Roboto+Condensed" rel="stylesheet"></link>

				{this.props.multiLoginAlertShow && <MultiLoginAlert />}

				{this.state.showTestModal && (
					<StudentTestAlert
						showTestModal={this.state.showTestModal}
						closeTestModal={this.closeTestModal}
						testRoute={this.state.testRoute}
						testAlertData={this.state.testAlertData}
						history={this.props.history}
					/>
				)}

				{!this.state.headerDisabled && (
					<Header
						headerLabel="CodingBar"
						togglePlayground={this.togglePlayground}
						toggleZoom={this.toggleZoom.bind(this)}
						headerDisabled={this.state.headerDisabled}
					/>
				)}

				{!this.state.showError ? this.getCloudPage() : ''}

				<BodyContent
					initSuc={this.props.initSuc}
					loggedIn={this.props.user ? true : false}
					userRole={this.props.user ? this.props.user.get('role') : undefined}
					showError={this.state.showError}
					bug={this.state.bug}
					desktopVersion={this.state.desktopVersion}
					showVersionUpgrade={this.state.showVersionUpgrade}
					closeErrorHandler={this.closeErrorHandler.bind(this)}
					closeVersionCheckBox={this.closeVersionCheckBox}
					onlineStatus={this.props.onlineStatus}
				/>
				<ToastQueue />
			</CodingbarThemeProvider>
		);
	}
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(App);
